<template>
  <div class="position-relative">
    <b-table
      :fields="fields"
      :items="getItems"
      striped
    >
      <template #head()="{ label }">
        {{ $t(label) }}
      </template>
      <template #cell(title)="{ item }">
        <div
          class="d-flex align-items-center"
          style="cursor: default"
          @dblclick="openItem(item)"
        >
          <feather-icon
            size="20"
            :icon="item.is_folder ? 'FolderIcon' : 'FileIcon'"
          />
          <h5 class="mb-0 ml-50">
            {{ item.title }}
          </h5>
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <template v-if="item.id">
          <b-button
            :title="$t('general.open')"
            class="p-50 mr-50"
            variant="info"
            @click="openItem(item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            :title="$t('general.edit')"
            class="p-50 mr-50"
            variant="warning"
            @click="editClicked(item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            :title="$t('general.delete')"
            class="p-50"
            variant="danger"
            @click="deleteClicked(item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </template>
    </b-table>
    <b-overlay
      :show="tableBusy"
      no-wrap
      opacity="0.5"
    />
  </div>
</template>

<script>
import { BTable, BOverlay, BButton } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BOverlay,
    BButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    tableBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
    inFolder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'tests.id',
        },
        {
          key: 'title',
          label: 'general.title',
        },
        {
          key: 'modified_date',
          label: 'general.updated_at',
        },
        {
          key: 'created_date',
          label: 'general.created_at',
        },
        {
          key: 'actions',
          label: 'general.actions',
          tdClass: 'width-200',
        },
      ],
    }
  },
  computed: {
    getItems() {
      // this.items.unshift({ id: this.parent, title: '...' })
      if (this.inFolder) {
        const newItems = [{ id: null, title: '...', is_folder: true }].concat(this.items)
        return newItems
      }
      return this.items
    },
  },
  methods: {
    openItem(item) {
      if (item.is_folder) {
        if (item.id) {
          this.$emit('openFolder', item.id)
        } else {
          this.$emit('backToParent')
        }
      } else {
        this.$emit('openItem', item.id)
      }
    },
    editClicked(item) {
      if (item.is_folder) this.$emit('editFolder', item)
      else this.$emit('editTest', item.id)
    },
    deleteClicked(id) {
      this.$_showAreYouSureModal().then(answ => {
        if (answ) {
          this.$emit('deleteItem', id)
        }
      })
    },
  },
}
</script>

<style>

</style>
