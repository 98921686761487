<template>
  <div>
    <section class="d-flex justify-content-between align-items-end">
      <!-- <b-form-group :label="$t('general.search')+':'">
        <b-form-input v-model="query_params.search" />
      </b-form-group> -->
      <!-- <b-form-radio-group
        v-model="itemView"
        class="ml-1 mb-1 list item-view-radio-group"
        buttons
        size="sm"
        button-variant="outline-primary"
      >
        <b-form-radio
          v-for="option in itemViewOptions"
          :key="option.value"
          :value="option.value"
        >
          <feather-icon
            :icon="option.icon"
            size="18"
          />
        </b-form-radio>
      </b-form-radio-group> -->
    </section>
    <section>
      <b-card>
        <div class="d-flex mb-1">
          <b-button
            v-b-modal.folder-modal
            class="p-50"
            variant="primary"
            @click="openNewFolderModal"
          >
            <feather-icon
              size="20"
              icon="FolderPlusIcon"
            />
            {{ $t('tests.add_folder') }}
          </b-button>
          <b-button
            v-b-modal.test-type-select-modal
            class="p-50 ml-1"
            variant="success"
            @click="openTypeSelectModal"
          >
            <feather-icon
              size="20"
              icon="FilePlusIcon"
            />
            {{ $t('tests.add_test') }}
          </b-button>
        </div>
        <table-view
          :items="GET_QUESTION_BASE"
          :table-busy="table_busy"
          :in-folder="Boolean(query_params.parent)"
          @openFolder="openFolder"
          @editFolder="editFolder"
          @deleteItem="deleteItem"
          @editTest="editTest"
          @backToParent="openFolder(parent_folder_id)"
        />
      </b-card>
    </section>
    <add-or-edit-folder-modal
      :data="selected_folder"
      :is-edit="selected_folder.id"
      @save="saveClick"
    />
    <test-type-select-modal @save="typeSelected" />
  </div>
</template>

<script>
import {
  // BFormRadioGroup, BFormRadio,
  // BFormInput, BFormGroup,
  BButton, BCard,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import addOrEditFolderModal from './components/modals/addOrEditFolderModal.vue'
import tableView from './components/tables/tableView.vue'
import testTypeSelectModal from './components/modals/testTypeSelectModal.vue'

export default {
  components: {
    // BFormRadioGroup,
    // BFormRadio,
    // BFormInput,
    // BFormGroup,
    BButton,
    BCard,
    addOrEditFolderModal,
    testTypeSelectModal,
    tableView,
  },
  data() {
    return {
      query_params: {
        search: '',
        parent: null,
      },
      parent_folder_id: null,
      table_busy: false,
      selected_folder: {
        name: '',
        parent: null,
      },
      items: [
        {
          id: 1,
          questions: 2,
          subject: 'Maths',
          title: 'test',
          is_folder: true,
          created_at: '2022-01-31',
          updated_at: '2022-02-28',
        },
        {
          id: 2,
          questions: 3,
          subject: 'Maths',
          title: 'test 2',
          is_folder: false,
          created_at: '2022-01-30',
          updated_at: '2022-02-25',
        },
      ],
      itemView: '',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
    }
  },
  computed: {
    ...mapGetters('questions', ['GET_QUESTION_BASE']),
  },
  mounted() {
    this.FETCH_QUESTION_BASE(this.query_params)
  },
  methods: {
    ...mapActions('questions', ['FETCH_QUESTION_BASE', 'ADD_QUESTION_FOLDER', 'EDIT_QUESTION_FOLDER', 'FETCH_ONE_QUESTION_FOLDER']),
    openNewFolderModal() {
      this.selected_folder = {
        name: '',
        parent: this.query_params.parent,
      }
    },
    refresh() {
      this.FETCH_QUESTION_BASE(this.query_params).then(() => {
        this.table_busy = false
      })
    },
    saveClick(item) {
      (item.id ? this.EDIT_QUESTION_FOLDER(item) : this.ADD_QUESTION_FOLDER(item)).then(() => {
        this.$bvModal.hide('folder-modal')
        this.refresh()
      })
    },
    openFolder(id) {
      // this.parent_folder_id = this.$_removeObjectConnection(this.query_params.parent)
      this.query_params.parent = id
      this.table_busy = true
      this.refresh()
      if (this.query_params.parent) {
        this.FETCH_ONE_QUESTION_FOLDER(this.query_params.parent).then(resp => {
          console.log(resp)
          this.parent_folder_id = resp.data.parent
          console.log(this.parent_folder_id)
        })
      } else {
        this.parent_folder_id = null
      }
    },
    editFolder(item) {
      this.selected_folder = this.$_removeObjectConnection(item)
      this.$bvModal.show('folder-modal')
    },
    deleteItem(id) {
      this.EDIT_QUESTION_FOLDER({ is_active: false, id }).then(() => {
        this.refresh()
      })
    },
    openTypeSelectModal() {
    },
    typeSelected(type) {
      this.$router.push({ name: 'testing-add-question', params: { folder_id: this.query_params.parent || 0, type } })
    },
    editTest(id) {
      this.$router.push({ name: 'testing-edit-question', params: { id } })
    },
  },
}
</script>

<style>

</style>
