<template>
  <b-modal
    id="test-type-select-modal"
    :title="$t('tests.select_type_of_test')"
    :ok-title="$t('general.select')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok.prevent="saveClick"
  >
    <validation-observer ref="typeValidations">
      <b-form-group :label="$t('tests.test_type')+':'">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('tests.test_type')"
        >
          <v-select
            v-model="type"
            :options="$data.$_test_type_options"
            :get-option-label="opt => opt.title[$i18n.locale]"
            :reduce="opt => opt.value"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup } from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      required,
      type: null,
    }
  },
  methods: {
    async saveClick() {
      const success = await this.$refs.typeValidations.validate()
      if (!success) return
      this.$emit('save', this.type)
    },
  },
}
</script>

<style>

</style>
